import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Cennik kosmetyczki w Olsztynie | Gabinet Elżbieta</title>
    <meta name="description" content="Poznaj dokładne ceny usług i zabiegów kosmetycznych w Gabinecie Elżbieta w Olsztynie. Sprawdź ile kosztuje u nas zabieg elektrolizy, piercing uszu, dermapen, sonoforeza, retix c." />
    <link rel="canonical" href="https://gabinetelzbieta.pl/cennik/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  table {
    border: 1px solid #e2e2e2;
    border-left: 0;
    border-bottom: 0;
    border-spacing: 0;
    font-size: 14px;
    margin: 0 -15px 50px;
    width: calc(100% + 30px);

    @media only screen and (min-width: 480px) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    @media only screen and (min-width: 640px) { 
      font-size: 16px;
    }

    tr {
      &:nth-child(even) {
        td {
          background: #f4f4f4;
        }
      }

      &:hover {
        td {
          background: hsl(347,73%,95%);
        }
      }
    }

    td {
      transition: background 150ms ease-in;
      border-left: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      line-height: 130%;
      padding: 10px;

      &:last-child {
        width: 100px;
        text-align: right;

        @media only screen and (min-width: 640px) {
          padding: 10px 15px;
          width: 185px;
        }
      }
    }
  }

  .discount-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    max-width: 640px;
    margin: 0 auto 45px;

    @media only screen and (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 75px;
    }
  }

  .discount {
    color: ${({ theme }) => theme.white};
    border-radius: 5px;
    max-width: 360px;
    margin: 0 auto 10px;
    padding: 3rem 1rem;
    text-align: center;
    width: 100%;

    &--blue {
      background: #008cff;
    }

    &--orange {
      background: #f08e43;
    }

    &--pink {
      background: ${({ theme }) => theme.primaryLight};
    }

    p {
      &:nth-child(2) {
        font-size: 5rem;
        margin: -15px 0 10px;
        font-weight: 600;

        span {
          font-size: 5rem;
        }
      }
      &:last-child {
        text-transform: uppercase;
      }
    }
  }
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_2)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <p className="subtitle">Nasz usługi</p>
        <h1>Cennik</h1>

        <h2 style={{
          margin: '0 0 35px',
          textAlign: 'center',
        }}
        >
          Promocje na hasło:
          {' '}
          <span className="text-uppercase">&quot;Elżbieta&quot;</span>
        </h2>

        <div className="discount-wrap">
          <div className="discount discount--pink">
            <p>
              Dla
              {' '}
              <strong>
                nowych
              </strong>
              {' '}
              klientów

            </p>
            <p>
              -20
              <span>%</span>
            </p>
            <p className="no-marg">Na pierwszy zabieg!!!</p>
          </div>

          <div className="discount discount--blue">
            <p>
              Dla
              {' '}
              <strong>wszystkich</strong>
              {' '}
              klientów
            </p>
            <p>
              -20
              <span>%</span>
            </p>
            <p className="no-marg">Na Voucher podarunkowy</p>
          </div>
        </div>

        <h2>Zabiegi na twarz, szyję i&nbsp;dekolt</h2>

        <table>
          <tbody>
            <tr>
              <td>masaż twarzy, szyi i dekoltu (peeling + maska odżywcza)</td>
              <td>od 90,00 zł</td>
            </tr>
            <tr>
              <td>cały zabieg (masaż twarzy, szyi i dekoltu+maska + oczyszczanie manualne twarzy + D`arsonval + ampułka + maska algowa, krem)</td>
              <td>od 190,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Zabiegi z oczyszczaniem manualnym</h2>

        <table>
          <tbody>
            <tr>
              <td>peeling + maska rozpulchniająca + oczyszczanie manualne + D&apos;arsonval + ultradźwięki + maska + krem</td>
              <td>od 150,00 zł</td>
            </tr>
            <tr>
              <td>peeling + maska rozpulchniająca + oczyszczanie manualne + D&apos;arsonval + ultradźwięki + maska + krem + algi</td>
              <td>od 160,00 zł</td>
            </tr>
            <tr>
              <td>mikrodermabrazja diamentowa twarzy + kwas owocowy + oczyszczanie manualne + ultradźwięki + maska + krem</td>
              <td>od 180,00 zł</td>
            </tr>
            <tr>
              <td>peeling + maska rozpulchniająca + oczyszczanie manualne + D&apos;arsonval + ultradźwięki + algi ciepłe „Thalgo” + krem</td>
              <td>od 160,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Mikrodermabrazja diamentowa</h2>

        <table>
          <tbody>
            <tr>
              <td>mikrodermabrazja diamentowa twarzy + kwas owocowy + ultradźwięki + maska + krem</td>
              <td>od 180,00 zł</td>
            </tr>
            <tr>
              <td>mikrodermabrazja diamentowa twarzy + kwas owocowy + ultradźwięki + maska + krem + algi</td>
              <td>od 200,00 zł</td>
            </tr>
            <tr>
              <td>mikrodermabrazja diamentowa twarzy, szyi i dekoltu + kwas owocowy + ultradźwięki + maska, krem</td>
              <td>od 220,00 zł</td>
            </tr>
            <tr>
              <td>mikrodermabrazja diamentowa twarzy, szyi i dekoltu + kwas owocowy + ultradźwięki + maska, krem +algi</td>
              <td>od 240,00 zł</td>
            </tr>
            <tr>
              <td>mikrodermabrazja diamentowa pleców + kwas owocowy + ultradźwięki + maska, krem</td>
              <td>od 230,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Mezoterapia igłowa</h2>

        <table>
          <tbody>
            <tr>
              <td>mezoterapia igłowa + kwas owocowy + ultradźwięki + maska + krem</td>
              <td>od 200,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Złuszczanie chemiczne</h2>

        <table>
          <tbody>
            <tr>
              <td>Retix C (4% retinol + 8% witamina C)</td>
              <td>od 240,00 zł</td>
            </tr>
            <tr>
              <td>kwas migdałowy + ampułka + ultradźwięki + maska + krem</td>
              <td>od 160,00 zł</td>
            </tr>
            <tr>
              <td>kwas pirogronowy + kwas azelainowy + kwas salicylowy + ampułka + ultradźwięki + maska + krem</td>
              <td>od 170,00 zł</td>
            </tr>
            <tr>
              <td>witamina C „Image” 30% + serum + maska + krem</td>
              <td>240,00 zł</td>
            </tr>
            <tr>
              <td>witamina C „Image” 30% + serum + maska +masaż + krem</td>
              <td>270,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Ultradźwięki</h2>

        <table>
          <tbody>
            <tr>
              <td>peeling kawitacyjny</td>
              <td>od 70,00 - 90 zł</td>
            </tr>
            <tr>
              <td>ultradźwięki + ampułka na naczynia + krem</td>
              <td>od 100,00 zł</td>
            </tr>
            <tr>
              <td>ultradźwięki + ampułka liftingująca + krem</td>
              <td>od 100,00 zł</td>
            </tr>
            <tr>
              <td>ultradźwięki + ampułka lifitingująca + algi zimne + krem</td>
              <td><strong>150,00 zł</strong></td>
            </tr>
          </tbody>
        </table>

        <h2>Usuwanie nadmiernego owłosienia</h2>

        <table>
          <tbody>
            <tr>
              <td>elektroliza</td>
              <td>0,90 zł za impuls</td>
            </tr>
          </tbody>
        </table>

        <h2>Henna</h2>

        <table>
          <tbody>
            <tr>
              <td></td>
              <td>od 40,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Depilacja woskiem</h2>

        <table>
          <tbody>
            <tr>
              <td>depilacja nóg</td>
              <td>od 40,00 zł</td>
            </tr>
            <tr>
              <td>depilacja twarzy</td>
              <td><strong style={{ color: '#f6798f' }}>od 20,00 zł</strong></td>
            </tr>
            <tr>
              <td>depilacja pachwin</td>
              <td>od 30,00 – 80,00 zł</td>
            </tr>
            <tr>
              <td>depilacja rąk</td>
              <td>od 30,00 – 65,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Depilacja męska</h2>

        <table>
          <tbody>
            <tr>
              <td>klatka piersiowa</td>
              <td>60,00 zł</td>
            </tr>
            <tr>
              <td>plecy</td>
              <td>80,00 - 140,00 zł</td>
            </tr>
          </tbody>
        </table>

        <h2>Przekłuwanie uszu</h2>

        <table>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>od 49,00 zł</td>
            </tr>
          </tbody>
        </table>
        <p style={{
          textAlign: 'right',
          margin: '80px 0 0',
          fontSize: '15px',
        }}
        >
          Ceny obowiązują od: 01.02.2023 r.
        </p>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_2: file(relativePath: {regex: "/hero\/2.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default IndexPage;
